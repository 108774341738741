import React from "react";
import RadioInput from "./RadioInput";
import { useTranslation } from "react-i18next";

const Fragen = ({ handleChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-medium md:text-lg">{t("Frage_1")}</div>
      <div className="md:flex md:items-start mb-6">
        <div className="mr-4">
          <RadioInput
            name="hoehle"
            value="1"
            label={t("Antwort_1_1")}
            onChange={handleChange}
          ></RadioInput>
        </div>
        <div className="mr-4">
          <RadioInput
            name="hoehle"
            value="2"
            label={t("Antwort_1_2")}
            onChange={handleChange}
          ></RadioInput>
        </div>
        <div className="mr-4">
          <RadioInput
            name="hoehle"
            value="3"
            label={t("Antwort_1_3")}
            onChange={handleChange}
          ></RadioInput>
        </div>
      </div>

      <div className="text-medium md:text-lg">{t("Frage_2")}</div>
      <div className="md:flex md:items-start   mb-6">
        <div className="mr-4">
          <RadioInput
            name="zeit"
            value="1"
            label={t("Antwort_2_1")}
            onChange={handleChange}
          ></RadioInput>
        </div>
        <div className="mr-4">
          <RadioInput
            name="zeit"
            value="2"
            label={t("Antwort_2_2")}
            onChange={handleChange}
          ></RadioInput>
        </div>
        <div className="mr-4">
          <RadioInput
            name="zeit"
            value="3"
            label={t("Antwort_2_3")}
            onChange={handleChange}
          ></RadioInput>
        </div>
      </div>

      <div className="text-medium md:text-lg">{t("Frage_3")}</div>
      <div className="md:flex md:items-start mb-6">
        <div className="mr-4">
          <RadioInput
            name="temperatur"
            value="1"
            label={t("Antwort_3_1")}
            onChange={handleChange}
          ></RadioInput>
        </div>
        <div className="mr-4">
          <RadioInput
            name="temperatur"
            value="2"
            label={t("Antwort_3_2")}
            onChange={handleChange}
          ></RadioInput>
        </div>
        <div className="mr-4">
          <RadioInput
            name="temperatur"
            value="3"
            label={t("Antwort_3_3")}
            onChange={handleChange}
          ></RadioInput>
        </div>
      </div>
    </div>
  );
};

export default Fragen;
