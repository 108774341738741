import React, { useEffect, useState, useRef } from "react";
import { fillSAPform } from "./helper/fillSAPform";
import { API_URL } from "./config";
import ReactPlayer from "react-player";
import {
  optionItemsTage,
  optionItemsMonate,
  optionItemsJahre,
  optionItemsAnrede_de,
  optionItemsAnrede_fr,
  optionItemsLaender,
} from "./helper/optionsItems";
import "./App.css";
import {
  SAP_IDS,
  PATTERN_EMAIL,
  SAP_BUTTON_ID,
  MODE,
} from "./helper/constants";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Fragen from "./components/components/Fragen";
import Form from "./components/components/Form";
import KB_logo from "./images/kaltbach_logo.svg";
import header_image from "./images/kaltbach_cave.jpg";
//import montana from "./images/Hotel Montana Luzern.png";
import montana from "./images/Hotel Montana Luzern rund.png";
//import montana from "./images/Hotel Montana Luzern rund mit appenzeller.png";
import Card from "./components/components/Card";
import teaser_aktuelles from "./images/Aktuelles_Teaser.jpg";
import teaser_veredelung from "./images/Veredelung_Teaser.jpg";
import teaser_hoehle from "./images/Hoehle_Teaser.jpg";
import teaser_rezepte from "./images/Rezepte_Teaser.jpg";

const initialFormData = {
  anrede: "",
  vorname: "",
  nachname: "",
  email: "",
  tag: "",
  monat: "",
  jahr: "",
  land: "CH",
  teilnahmebedingungen: false,
  hoehle: null,
  zeit: null,
  temperatur: null,
};

let sapError = "";

function App({ language }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  const md = useMediaQuery({ query: "(min-width: 768px)" });
  const sm = useMediaQuery({ query: "(min-width: 640px)" });
  const lg = useMediaQuery({ query: "(min-width: 1024px)" });
  const xl = useMediaQuery({ query: "(min-width: 1280px)" });

  /* i18n.changeLanguage(language); */

  // Use State Hooks
  const [form, setForm] = useState(initialFormData);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageHeader, setMessageHeader] = useState("");
  const [formToValidate, setFormToValidate] = useState("");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState(undefined);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [showVideo, setShowVideo] = useState(true);
  const [showDankeScreen, setShowDankeScreen] = useState(false);

  // Daten in mysql Datenbank schreiben
  const sendDataToDB = () => {
    let base_url = "";
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      base_url = API_URL.URL_LOCALE;
    } else {
      base_url = API_URL.URL_REMOTE;
    }
    axios
      .get(base_url + "store.php", {
        params: { ...form, sapError },
      })
      .then(function (response) {
        // handle success
        //console.log(response);
      })
      .catch(function (error) {
        // handle error
        //console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  // Erster Buchstabe uppercase
  function jsUcfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Form Validierung
  // SetFormToValidate Hook damit das richtige Element den error status von semantic bekommt
  // Einige Felder werde mit RegEx Pattern getestet. Die Pattern sind aus den SAP Files
  const isValidForm = () => {
    // check Fragen
    if (
      form.hoehle === null ||
      form.temperatur === null ||
      form.zeit === null
    ) {
      setFormToValidate("anrede");
      setMessageHeader(t("Fragen"));
      setMessage(t("Fragen.err"));
      setError(true);
      return false;
    }

    //check Anrede
    if (form.anrede === "") {
      setFormToValidate("anrede");
      setMessageHeader(t("Anrede"));
      setMessage(t("Element.err"));
      setError(true);
      return false;
    }
    if (form.vorname === "") {
      setFormToValidate("vorname");
      setMessageHeader(t("Vorname"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }
    if (form.nachname === "") {
      setFormToValidate("nachname");
      setMessageHeader(t("Nachname"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }

    if (form.tag === "") {
      setFormToValidate("tag");
      setMessageHeader(t("Tag"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }
    if (form.monat === "") {
      setFormToValidate("monat");
      setMessageHeader(t("Monat"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }
    if (form.jahr === "") {
      setFormToValidate("jahr");
      setMessageHeader(t("Jahr"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }
    // Alterscheck
    const dob = form.jahr + form.monat + form.tag;
    const year = Number(dob.substr(0, 4));
    const month = Number(dob.substr(4, 2)) - 1;
    const day = Number(dob.substr(6, 2));
    const today = new Date();
    let age = today.getFullYear() - year;
    if (
      today.getMonth() < month ||
      (today.getMonth() === month && today.getDate() < day)
    ) {
      age--;
    }

    if (parseInt(age) < 18) {
      setFormToValidate("");
      setMessageHeader(t("Alter.err"));
      setMessage("");
      setError(true);
      return false;
    }

    if (form.land === "") {
      setFormToValidate("land");
      setMessageHeader(t("Land"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }

    if (!PATTERN_EMAIL.test(form.email)) {
      setFormToValidate("email");
      setMessageHeader(t("Email"));
      setMessage(t("Email.err"));
      setError(true);
      return false;
    }

    if (form.teilnahmebedingungen === false) {
      setFormToValidate("teilnahmebedingungen");
      setMessageHeader(t("tb.teil.2"));
      setMessage(t("Pflichtfeld.err"));
      setError(true);
      return false;
    }

    // Alles ok !
    setMessageHeader(t("Danke"));
    setMessage(t("Verlosung"));
    return true;
  };

  // Form submit Handler
  // fillSapForm mit 'test' oder 'prod'
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidForm()) {
      setLoading(true);
      fillSAPform(MODE, form, SAP_IDS, language);
      document.getElementById(SAP_BUTTON_ID[MODE + "_id"][language]).click();
      setSubmit(true);
      setLoading(false);
      sendDataToDB();
      setShowDankeScreen(true);
    }
  };

  // Form change Handler
  // bei select Inputs hat es einen zusätzlichen Event Parameter (payload)
  const handleChange = (event, payload) => {
    setError(false);
    if (payload) {
      // selects
      const { value, name, checked } = payload;
      console.log(value, name, checked);
      setForm({
        ...form,
        [name]: value ? value : checked,
      });
    } else {
      event.target.value = jsUcfirst(event.target.value);
      console.log(event.target.value);
      if (event.target.name === "email") {
        event.target.value = event.target.value.toLowerCase();
      }
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
      if (event.target.name === "teilnahmebedingungen") {
        setForm({
          ...form,
          [event.target.name]: !form.teilnahmebedingungen,
        });
      }
    }
  };

  const handleOnBlur = (event) => {
    event.target.value = event.target.value.toLowerCase();
    let nameCapitalized =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    nameCapitalized = nameCapitalized.replace(/(^|[\s-])\S/g, function (match) {
      return match.toUpperCase();
    });
    event.target.value = nameCapitalized;
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  // Render
  return (
    <div className="font-normal">
      <div className="w-full py-3 md:py-6 md:px-24 lg:px-32">
        <div className="container relative mx-auto">
          <div className="flex absolute text-kb_gold text-sm leading-none">
            <div
              className="cursor-pointer"
              onClick={() => {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  process.env.PUBLIC_URL +
                  "/de";
                window.location.href = newURL;
              }}
            >
              DE
            </div>
            <div
              className="ml-4 cursor-pointer"
              onClick={() => {
                const newURL =
                  window.location.protocol +
                  "//" +
                  window.location.host +
                  process.env.PUBLIC_URL +
                  "/fr";
                window.location.href = newURL;
              }}
            >
              FR
            </div>
          </div>
          <div className="">
            <img
              src={KB_logo}
              alt="kaltbach_logo"
              className="mx-auto w-20 md:w-32"
            />
          </div>
        </div>
      </div>

      <div
        className="w-full border border-t-1 border-l-0 border-r-0 border-b-0 border-gray-500"
        style={{ height: "60vh" }}
      >
        <img src={header_image} alt="" className="h-full w-full object-cover" />
      </div>

      <div className="w-full -mt-24 py-3 md:py-6 md:px-24 lg:px-32">
        <div className="container relative mx-auto bg-black">
          <div className="h-32" style={{ backgroundColor: "#fde217" }}>
            <div className="flex text-left h-32 px-4  text-black ">
              <div
                className="flex items-center"
                style={{ flexBasis: md ? "70%" : "100%" }}
              >
                <div
                  style={{ lineHeight: "60px", fontFamily: "Timeout" }}
                  className="text-4xl md:text-6xl font-semibold"
                >
                  WIN
                </div>
                <div className="pl-4">
                  <div className="hidden lg:block font-bold text-base leading-tight">
                    {t("Teaser_1")}
                  </div>
                  <div className="leading-tight">{t("Teaser_2")}</div>
                </div>
              </div>
              <div
                style={{ flexBasis: md ? "30%" : "0%" }}
                className="text-right relative"
              >
                <img
                  className="absolute right-0 bottom-0"
                  src={montana}
                  style={{ width: "400px" }}
                  alt=""
                />
              </div>
            </div>
          </div>

          {showDankeScreen && (
            <div className=" px-4 md:px-0">
              <div className="text-base2 md:text-xl pt-4 uppercase font-medium">
                {t("Titel_Danke")}
              </div>
              <div className="text-base md:text-lg mt-6">
                {t("Subtitel_Danke")}
              </div>
            </div>
          )}

          {!showDankeScreen && (
            <>
              <div className=" px-4 md:px-0">
                <div className="text-base2 md:text-xl pt-4 uppercase font-medium">
                  {t("Titel")}
                </div>
                <div className="text-base md:text-lg mt-6">{t("Subtitel")}</div>
              </div>

              <div className="bg-black text-white mt-4 px-4 md:px-0">
                <div className="pt-12">
                  <Fragen handleChange={handleChange}></Fragen>
                </div>
                {/* {!showVideo && ( */}
                <div className="text-medium md:text-lg mt-6 text-kb_gold font-medium">
                  {t("Schwierig")}
                </div>
                {/* )} */}
                {showVideo && (
                  <div className="player-wrapper">
                    <ReactPlayer
                      url={
                        i18n.language === "fr"
                          ? "https://player.vimeo.com/external/467028645.hd.mp4?s=45c4ec6172db58427af9072507edaa3dede8df60&profile_id=175"
                          : "https://player.vimeo.com/external/459845795.hd.mp4?s=187b2f4107f24cb025835e781c5cef9dc2397ae0&profile_id=174"
                      }
                      className="react-player"
                      playing={showVideo}
                      controls={true}
                      width="100%"
                      height="100%"
                      outline="none"
                    />
                  </div>
                )}
                <button
                  className="text-tiny outline-none border-0 mt-4 mb-8 font-medium bg-kb_gold hover:bg-kb_gold text-gray-800 uppercase py-2 px-6"
                  onClick={() => {
                    setShowVideo(!showVideo);
                  }}
                >
                  {!showVideo ? t("Video_anzeigen") : t("Video_verbergen")}
                </button>
                <Form
                  handleSubmit={handleSubmit}
                  handleOnBlur={handleOnBlur}
                  handleChange={handleChange}
                  form={form}
                  optionItemsAnrede_de={optionItemsAnrede_de}
                  optionItemsAnrede_fr={optionItemsAnrede_fr}
                  optionItemsMonate={optionItemsMonate}
                  optionItemsJahre={optionItemsJahre}
                  optionItemsTage={optionItemsTage}
                  message={message}
                  messageHeader={messageHeader}
                  submit={submit}
                  error={error}
                ></Form>
                <div className="text-medium md:text-lg mt-6 mb-4 ">
                  {t("Weitere")}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-6">
                  <Card
                    bild={teaser_aktuelles}
                    titel={t("Aktuell_Titel")}
                    teaser={t("Aktuell_Teaser")}
                    link={t("Aktuell_Link")}
                    url={t("Aktuell_URL")}
                  ></Card>
                  <Card
                    bild={teaser_veredelung}
                    titel={t("Veredelung_Titel")}
                    teaser={t("Veredelung_Teaser")}
                    link={t("Veredelung_Link")}
                    url={t("Veredelung_URL")}
                  ></Card>
                  <Card
                    bild={teaser_hoehle}
                    titel={t("Hoehle_Titel")}
                    teaser={t("Hoehle_Teaser")}
                    link={t("Hoehle_Link")}
                    url={t("Hoehle_URL")}
                  ></Card>
                  <Card
                    bild={teaser_rezepte}
                    titel={t("Rezepte_Titel")}
                    teaser={t("Rezepte_Teaser")}
                    link={t("Rezepte_Link")}
                    url={t("Rezepte_URL")}
                  ></Card>
                </div>
              </div>

              <div className="font-normal text-white text-medium mt-8">
                {t("Quicklinks")}
              </div>
              <div
                className="text-gray-500 font-base mt-4 cursor-pointer"
                onClick={() => {
                  window.open(t("Rechtliches_Link"));
                }}
              >
                {t("Rechtliches")}
              </div>
              <div
                className="text-gray-500 font-base mt-4 cursor-pointer"
                onClick={() => {
                  window.open(
                    process.env.PUBLIC_URL + "tb_" + i18n.language + ".pdf"
                  );
                }}
              >
                {t("TB")}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
