import React from "react";

const Card = ({ bild, titel, link, teaser, url }) => {
  return (
    <div
      onClick={() => {
        window.open(url);
      }}
      className="text-gray-900 bg-white cursor-pointer"
    >
      <div>
        <img src={bild} alt="" style={{ padding: "1px", width: "100%" }} />
      </div>
      <div className="pt-6 px-8">
        <div className="text-kb_gold">{titel}</div>
        <div className="text-medium uppercase font-normal mt-4">{teaser}</div>
        <div className="uppercase font-normal mt-8 pb-6">{link}</div>
      </div>
    </div>
  );
};

export default Card;
