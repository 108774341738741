// DAS KOMMT DANN IN EIN CONFIG FILE !!!!!
// Steuert welche ids verwendet werden
export const MODE = "prod";

// Die Ids sind für prod und test unterschiedlich
// Und auch für die Sprachen !!!!!!!!!!!!!!!!!!!!!!!!!!
export const SAP_IDS = [
  {
    name: "anrede",
    test_id: {
      de: "__down80",
      fr: "__down60",
    },
    prod_id: {
      de: "__down90",
      fr: "__down110",
    },
    typ: "normal",
  },
  {
    name: "vorname",
    test_id: {
      de: "__input97",
      fr: "__input73",
    },
    prod_id: {
      de: "__input109",
      fr: "__input133",
    },
    typ: "normal",
  },
  {
    name: "nachname",
    test_id: {
      de: "__input98",
      fr: "__input74",
    },
    prod_id: {
      de: "__input110",
      fr: "__input134",
    },
    typ: "normal",
  },
  {
    name: "email",
    test_id: {
      de: "__input101",
      fr: "__input77",
    },
    prod_id: {
      de: "__input113",
      fr: "__input137",
    },
    typ: "normal",
  },
  {
    name: "land",
    test_id: {
      de: "__down81",
      fr: "__down61",
    },
    prod_id: {
      de: "__down91",
      fr: "__down111",
    },
    typ: "normal",
  },
  {
    name: "tag",
    test_id: {
      de: "__down82",
      fr: "__down62",
    },
    prod_id: {
      de: "__down92",
      fr: "__down112",
    },
    typ: "normal",
  },
  {
    name: "monat",
    test_id: {
      de: "__down83",
      fr: "__down63",
    },
    prod_id: {
      de: "__down93",
      fr: "__down113",
    },
    typ: "normal",
  },
  {
    name: "jahr",
    test_id: {
      de: "__down84",
      fr: "__down64",
    },
    prod_id: {
      de: "__down94",
      fr: "__down114",
    },
    typ: "normal",
  },
  {
    name: "teilnahmebedingungen",
    test_id: {
      de: "__box32",
      fr: "__box24",
    },
    prod_id: {
      de: "__box36",
      fr: "__box44",
    },
    typ: "checkbox",
  },
];

export const SAP_BUTTON_ID = {
  test_id: {
    de: "__button63",
    fr: "__button51",
  },
  prod_id: {
    de: "__button42",
    fr: "__button51",
  },
};

// Die RegEx Pattern für die Validierung des Formulars
export const PATTERN_EMAIL = new RegExp(
  // eslint-disable-next-line
  /* /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ */
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
);

/* export const PATTERN_TELEFON = new RegExp("^\\+(?:[0-9] ?){6,28}[0-9]$"); */
export const PATTERN_TELEFON = new RegExp("^\\+[1-9]{1}[0-9]{6,28}$");

export const PATTERN_PLZ = new RegExp(/^[0-9]{4}$/);
