/*
 * Schreibt die Werte aus dem React Formular in das SAP Html Formular
 * typ: prod oder test
 * data: die Form Daten aus React
 * Die ids der Elemente im SAP HTML File
 */
export const fillSAPform = (typ, data, sap_ids, sprache) => {
  sap_ids.forEach(item => {
    if (item.typ === "normal") {
      //console.log(item[typ + "_id"]);
      document.getElementById(item[typ + "_id"][sprache]).value =
        data[item.name];
    } else if (item.typ === "checkbox") {
      // checkbox
      document
        .getElementById(item[typ + "_id"][sprache])
        .getElementsByTagName("input")[0].checked = data[item.name];
    }
  });
};
