import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import "core-js";
import "./tailwind/tailwind.css";
import App from "./App";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ErrorNotFound from "./ErrorNotFound";

const baseUrl = process.env.PUBLIC_URL; // will be /hypercomp

ReactDOM.render(
  <Router>
    <Switch>
      <Route
        path={baseUrl + "/"}
        exact
        render={(props) => <App {...props} language="de" />}
      />
      <Route
        path={baseUrl + "/de/"}
        render={(props) => <App {...props} language="de" />}
      />
      <Route
        path={baseUrl + "/fr/"}
        render={(props) => <App {...props} language="fr" />}
      />
      <Route component={ErrorNotFound} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

/* ReactDOM.render(<App />, document.getElementById("root")); */
