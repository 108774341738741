import React from "react";

const RadioInput = ({ name, value, onChange, label }) => {
  return (
    /*                   <div class="md-radio">
                <input id="1" type="radio" name="g" />
                <label for="1">Option 1</label>
              </div> */
    <div className="md-radio">
      <input
        className="mr-2 leading-tight"
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        id={label}
      />
      <label htmlFor={label} className="block text-white text-lg">
        <span className="text-base">{label}</span>
      </label>
    </div>
  );
};

export default RadioInput;
