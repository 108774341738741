import React from "react";

const TextInput = ({ name, value, onChange, onBlur, label, placeholder }) => {
  return (
    <div>
      <label className="block uppercase tracking-wide text-gray-100 text-base font-bold mb-2">
        {label}
      </label>
      <input
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="text-base appearance-none block w-full bg-white text-gray-800 border border-gray-800 py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextInput;
