import React from "react";

const ErrorNotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "30px", fontSize: "100px" }}>
      <h2>404.</h2>
      <h2>The sky was cloudless and of a deep dark blue.</h2>
    </div>
  );
};

export default ErrorNotFound;
