import React from "react";
import Select from "./Select";
import TextInput from "./TextInput";
import { useTranslation } from "react-i18next";

const Form = ({
  handleSubmit,
  handleOnBlur,
  handleChange,
  form,
  optionItemsAnrede_de,
  optionItemsAnrede_fr,
  optionItemsMonate,
  optionItemsJahre,
  optionItemsTage,
  message,
  messageHeader,
  error,
  submit,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <form
        /* loading={loading} */
        style={{ marginTop: "20px" }}
        onSubmit={handleSubmit}
      >
        <div className="w-full md:w-1/3 mb-6 md:mb-6">
          <Select
            name="anrede"
            value={form.anrede}
            onChange={handleChange}
            options={
              i18n.language === "de"
                ? optionItemsAnrede_de
                : optionItemsAnrede_fr
            }
            label={"*" + t("Anrede")}
            placeholder={t("Anrede")}
          ></Select>
        </div>

        <div className="flex flex-wrap w-full mb-6 md:mb-0">
          <div className="w-full md:w-1/2 mb-6 md:mb-0">
            <TextInput
              label={"*" + t("Vorname")}
              placeholder={t("Vorname")}
              name="vorname"
              value={form.vorname}
              onChange={handleChange}
              onBlur={handleOnBlur}
            ></TextInput>
          </div>
          <div className="w-full md:w-1/2 md:px-3">
            <TextInput
              label={"*" + t("Nachname")}
              placeholder={t("Nachname")}
              name="nachname"
              value={form.nachname}
              onChange={handleChange}
              onBlur={handleOnBlur}
            ></TextInput>
          </div>
        </div>

        <div className="flex flex-wrap w-full md:mb-0">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <Select
              name="tag"
              value={form.tag}
              onChange={handleChange}
              options={optionItemsTage}
              label={"*" + t("Tag")}
              placeholder={t("Tag")}
            ></Select>
          </div>
          <div className="w-full md:w-1/3 md:px-3 mb-6 md:mb-0">
            <Select
              name="monat"
              value={form.monat}
              onChange={handleChange}
              options={optionItemsMonate}
              label={"*" + t("Monat")}
              placeholder={t("Monat")}
            ></Select>
          </div>
          <div className="w-full md:w-1/3 md:px-3 mb-6 md:mb-0">
            <Select
              name="jahr"
              value={form.jahr}
              onChange={handleChange}
              options={optionItemsJahre}
              label={"*" + t("Jahr")}
              placeholder={t("Jahr")}
            ></Select>
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-4 mb-6 md:mb-0">
          <div className="w-full md:w-1/2 mb-6 md:mb-0">
            <TextInput
              label={"*" + t("Email")}
              placeholder={t("Email")}
              name="email"
              value={form.email}
              onChange={handleChange}
            ></TextInput>
          </div>
        </div>

        <div className="md:flex md:items-center mb-2">
          <label className="md:w-2/3 block text-gray-100">
            <input
              className="mr-2 leading-tight"
              type="checkbox"
              onChange={handleChange}
              name="teilnahmebedingungen"
              value={form.teilnahmebedingungen}
            />
            <span className="text-base">{t("tb.teil.1")}</span>
            <span
              onClick={() => {
                window.open(
                  process.env.PUBLIC_URL + "tb_" + i18n.language + ".pdf"
                );
              }}
              className="text-base underline cursor-pointer"
            >
              {t("tb.teil.2")}
            </span>
          </label>
        </div>

        <div className="text-base" style={{ color: "#eee" }}>
          {t("pflichtfeld")}
        </div>

        <div className="text-white mt-2">
          {error ? (
            <div className="bg-red-600 py-2 px-2">
              {messageHeader}: {message}
            </div>
          ) : null}
          {submit ? (
            <>
              <div className="bg-green-600 py-2 px-2">
                {messageHeader}: {message}
              </div>
            </>
          ) : null}
        </div>

        <button
          type="submit"
          /*  disabled={submit}
              loading={loading} */
          className="text-tiny outline-none border-0 mt-4 mb-8 font-medium bg-kb_gold hover:bg-kb_gold text-gray-800 uppercase py-2 px-6"
        >
          {t("Anmelden.btn")}
        </button>
      </form>
    </div>
  );
};

export default Form;
